import $ from 'jquery'
import validate from 'jquery-validation'

function actionsFormGenericValidate() {

    let $genericForm = $('.umbraco-forms-form > form');
    
    $genericForm.submit(function (e) {
		//alert('submitting');
		$(this).find('input[type=submit]').prop('disabled', true);
	});
	
}

export default actionsFormGenericValidate
