import $ from 'jquery'
import validate from 'jquery-validation'

function actionsFormValidate() {

    let $applicationForm = $('#applicationForm')
    let $applicationReset = $('.application-reset')

    // Custom Validation For UK Postcodes
    $.validator.addMethod('postcode', function (value, element) {
        return this.optional(element) || /^((([A-PR-UWYZ][0-9])|([A-PR-UWYZ][0-9][0-9])|([A-PR-UWYZ][A-HK-Y][0-9])|([A-PR-UWYZ][A-HK-Y][0-9][0-9])|([A-PR-UWYZ][0-9][A-HJKSTUW])|([A-PR-UWYZ][A-HK-Y][0-9][ABEHMNPRVWXY]))\s?([0-9][ABD-HJLNP-UW-Z]{2})|(GIR)\s?(0AA))$/i.test(value)
    }, 'Please specify a valid UK postcode')

    // Application Form Validation
    let $validate = $applicationForm.validate({
        rules: {
            appPersonalEmail: {
                required: true,
                email: true,
            },
            appPersonalEmailConfirm: {
                email: true,
                required: true,
                equalTo: '#appPersonalEmail'
            },
            appRefereeEmail: {
                email: true,
                required: true,
            },
            appResidenceUKAddress1: {
                required: true,
                postcode: true
            },
        },
        ignore: ':hidden',
        errorClass: 'input-validation-error',
        validClass: 'input-validation-valid',
        highlight: function (element, errorClass, validClass) {
            $(element).removeClass(errorClass).removeClass(validClass).addClass(errorClass)
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass(errorClass).removeClass(validClass).addClass(validClass)
        },

        /* Doesn't seem to bind properly */
        submitHandler: function (form) {
            console.log('disabling button');
            form.addClass('a-btn--disabled');
            form.find("input[type=submit]").attr("disabled", "disabled");
            form.submit();
        }
    })

	/*
	$applicationForm.submit(function (e) {
		alert('submitting');
		$(this).find('input[type=submit]').prop('disabled', true);
	});
	*/

    $applicationReset.click(function (e) {
        e.preventDefault()
        $validate.reset()
        $applicationForm.trigger('reset')
        $('.input-validation-error').remove()
        $('.form-control').removeClass('input-validation-valid').removeClass('input-validation-error')
    })

}

export default actionsFormValidate
